import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { media } from "utils/Media";
import MainNav from "components/functional/mainNav";

import Layout from "components/layout";

import { StaticImage } from "gatsby-plugin-image";

const metaData = {
  title:
    "Truth, Beauty, Freedom, Love & PRIDE | DEWYNTERS",
  description: "Truth, Beauty, Freedom, Love & PRIDE",
  image: "images/news/truth.jpg",
};

const NewsBody = styled.section`
  width: 100%;

  .news {
    width: 85%;
    margin: var(--auto);
    max-width: 900px;
    @media ${media.sm} {
      padding-top: 2rem;
    }
  }

  .app-img {
    margin-bottom: 1rem;
  }
`;
const SingleNewsStyles = styled.article`
  position: relative;
  width: 100%;
  margin-bottom: 4rem;
  .image {
    margin: 1rem 0;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: var(--cyan);
    margin-bottom: 0.5rem;
  }

  h1 {
    font-weight: 800;
    font-size: 1.5rem;

    span {
      color: var(--cyan);
    }
    @media ${media.md} {
      font-size: 33px;
    }
  }

  .body {
    margin-top: 1rem;
    .highlight {
      color: #09dbd8;
    }
    a {
      text-decoration: underline 2px;
      color: #09dbd8 !important;
      &:hover,
      &:active {
        transform: scale(1.5);
      }
    }
    .no-colour-change {
      color: #000 !important;
    }
    p {
      margin-bottom: 1rem;

      strong {
        font-weight: 900;
        font-family: var(--PoppinsBold);
      }

      span,
      a {
        color: var(--cyan);
        font-weight: 800;
      }
    }
  }

  @media ${media.sm} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
  @media ${media.xl} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
`;
const LatestNews = ({ data }) => {
  return (
    <Layout
      title={metaData.title}
      description={metaData.description}
      image={metaData.image}
    >
      <MainNav alt />
      <NewsBody>
        <div className="news">
          <SingleNewsStyles>
            <div className="header">
              <StaticImage
                src="../../images/news/truth.jpg"
                alt="Truth, Beauty, Freedom, Love & PRIDE | Dewynters"
                className="app-img"
                layout="fullWidth"
              />
              <h1>Truth, Beauty, Freedom, Love & PRIDE</h1>
              <p>
                <strong>
                  By Anna Peachey, Richard McDermott & Holly Wilson{" "}
                </strong>
              </p>
            </div>
            <div className="body">
              <p>
                Brands taking a stand is the new normal. Consumers are
                increasingly demanding more transparency from their brands and
                are keen to know a company&rsquo;s stance on political or social
                issues.{" "}
                <strong>
                  According to YouGov, 48% of people say they are more likely to
                  buy from a brand if they express a view they agree with in
                  advertisements
                </strong>
                , going up to 59% for 18-34 year olds.&nbsp;
              </p>
              <p>
                Allyship isn&rsquo;t an end destination, and perfection
                isn&rsquo;t the goal. According to{" "}
                <a
                  href="https://www.forbes.com/sites/shereeatcheson/2018/11/30/allyship-the-key-to-unlocking-the-power-of-diversity/?sh=67b6330a49c6"
                  target="_blank"
                >
                  Forbes
                </a>{" "}
                an ally &lsquo;actively promotes and aspires to advance the
                culture of inclusion through intentional, positive and conscious
                efforts.&rsquo; Consumers want people with privilege -whether
                that&rsquo;s an individual or a brand with a large audience- to
                show up and speak up, to use their platform to advocate for
                marginalised or underrepresented communities.
              </p>
              <p>
                In 2018, Colin Kaepernick -the NFL player who originated the{" "}
                <em>taking the knee </em>protest against racism and police
                brutality in the US- found himself edged out of the league due
                to alleged fears from NFL bosses that this gesture of protest
                was too polarising for fans.
              </p>
              <p>
                Enter Nike with a powerful display of allyship. Partnering with
                Kaepernick, they released a television{" "}
                <a
                  href="https://www.youtube.com/watch?v=ekZRoSCINLA"
                  target="_blank"
                >
                  advert
                </a>{" "}
                with the central message:{" "}
                <strong>
                  &ldquo;Believe in something. Even if it means sacrificing
                  everything. Just do it
                </strong>
                <em>.&rdquo; </em>The advert prompted condemnation from Donald
                Trump, brand boycotts and public destruction of Nike goods.
                After an initial drop in sales, consumers soon got behind Nike
                and{" "}
                <a
                  href="https://www.marketwatch.com/story/nikes-online-sales-jumped-31-after-company-unveiled-kaepernick-campaign-2018-09-07"
                  target="_blank"
                >
                  sales soared
                </a>{" "}
                by 31% in the days following the release. Nike chose a defiant
                stance, and consumers got behind them to send a display of
                solidarity.
              </p>
              <p>
                With its Bohemian values of Truth, Beauty, Freedom and Love,{" "}
                <em>Moulin Rouge! The Musical</em> can truly own the statement
                &ldquo;Everyone is Welcome Here&rdquo;. So when it comes to
                championing social inclusivity and diversity,{" "}
                <em>Moulin Rouge! The Musical</em> and PRIDE are the perfect
                fit.&nbsp;
              </p>
              <p>
                This year, <em>Moulin Rouge! The Musical</em> was passionate
                about amplifying the PRIDE message with a fully rounded campaign
                moment, one that ended up touching all parts of the
                agency.&nbsp;
              </p>
              <p>
                The starting point was the addition of a fifth Bohemian value 
                &ndash; &lsquo;PRIDE&rsquo; &ndash; to its original four core
                values. This was then coupled with a core message from the show
                itself: &ldquo;Everyone Is Welcome Here&rdquo;. By then blending
                stylistic pillars of the <em>Moulin Rouge! The Musical</em>{" "}
                branding with the core PRIDE rainbow colours, these two key
                messages made for a striking and artful campaign throughout
                PRIDE Month.&nbsp;
              </p>
              <p>
                This was kickstarted by the installation of the PRIDE front of
                house, which updated the below-canopy and canopy-edge panels to
                carry the core values and key statement. Together they delivered
                an honest and eye-catching message to vast numbers of
                passers-by, only metres from the heart of Soho.
              </p>
              <div className="image">
                <StaticImage
                  src="../../images/news/pride.jpeg"
                  alt="Truth, Beauty, Freedom, Love & PRIDE | Dewynters"
                  className="app-img"
                  layout="fullWidth"
                />
                <p>
                  At the core of the PRIDE front of house installation was the
                  PRIDE Love Lock gate. Launched in a{" "}
                  <a
                    href="https://www.tiktok.com/@moulinmusicaluk/video/7239988808366771483"
                    target="_blank"
                  >
                    video by Baz Luhrmann
                  </a>{" "}
                  on the <em>Moulin Rouge! The Musical </em>social channels,
                  this initiative encouraged audiences to celebrate love by
                  attaching their own padlocks and messages to the gate at the
                  side of the Piccadilly.
                </p>
                <p>
                  It was important to ensure that no campaign activity could be
                  considered mere surface-level or rainbow-washing. Activity
                  either needed to celebrate, be a commitment to inclusivity, or
                  raise funds for our partner LGBTQ+ charity;{" "}
                  <a href="https://www.akt.org.uk/" target="_blank">
                    akt
                  </a>
                  . Akt supports young people who are facing homelessness,
                  helping them find safe homes and employment. Alongside bucket
                  collections at the Piccadilly Theatre, <em>L&rsquo;amour</em>{" "}
                  t-shirts were sold online and front of house, with all
                  proceeds going to akt.
                </p>
                <StaticImage
                  src="../../images/news/freedom.jpg"
                  alt="Truth, Beauty, Freedom, Love & PRIDE | Dewynters"
                  className="app-img"
                  layout="fullWidth"
                />
                <p>
                  Activity continued throughout June, including presence at the
                  Mighty Hoopla festival, and on social channels where LGBTQIA+
                  members of the company shared their own{" "}
                  <a
                    href="https://www.tiktok.com/@moulinmusicaluk/video/7247916708969647387?_t=8djqK220p1n&amp;_r=1"
                    target="_blank"
                  >
                    personal love stories
                  </a>{" "}
                  and spoke about what PRIDE means to them. With PRIDE in London
                  last weekend, we wanted to make sure{" "}
                  <em>Moulin Rouge! The Musical</em> was a visible ally.
                  Temporary tattoos of the bohemian values and PRIDE flag were
                  designed and distributed alongside <em>L&rsquo;amour</em>{" "}
                  fans, whilst digital 6-sheets were also live at key locations
                  in central London over the weekend, primarily ones close to
                  the parade route and at important transport hubs for those
                  travelling into London for the event.&nbsp;
                </p>
                <p>
                  Here at Dewynters we are proud to have partnered with our
                  client <em>Moulin Rouge! The Musical </em>on this PRIDE
                  campaign and look forward to supporting them in their ongoing
                  allyship to the LGBTQIA+ community.&nbsp;
                </p>
              </div>
            </div>
            <p>
              <strong>
                <Link to="/latest-news/">{`< Back`}</Link>
              </strong>
            </p>
          </SingleNewsStyles>
        </div>
      </NewsBody>
    </Layout>
  );
};

LatestNews.propTypes = {
  data: PropTypes.shape({
    News: PropTypes.any,
  }),
};

export default LatestNews;
